<template>
  <section class="about">

    <div class="inner_content_wrapper">

      <div class="content">
          <div class="intro_content">
            <p class="type" style="--n:10"><strong>&lt;hi></strong>Cześć!</p>
            <p class="fadeIn">Nazywam się Michał i jestem deweloperem portali, sklepów i stron internetowych.<br/></p>
            <p class="fadeIn">Programowanie to moja pasja, a tworzenie estetycznych i nowoczesnych stron sprawia mi wielką przyjemność. </p>
          </div>
          <div class="inner_content">
            <p><strong>&lt;tech></strong>Główne technologie oraz systemy, z którymi pracuję to:<br/></p>
            <div class="services">
              <div><span>Wordpress</span></div>
              <div><span>Webflow</span></div>
              <div><span>HubSpot</span></div>
              <div><span>VueJS</span></div>
              <div><span>PHP</span></div>
              <div><span>JavaScript</span></div>
            </div>
            <div class="content_wrap">
              <span><strong>&lt;offer></strong>Co oferuje:</span> 
              <div class="offer">
                <div><span>Strony internetowe</span></div>
                <div><span>Wizytowki / portfolio</span></div>
                <div><span>Landing page</span></div>
                <div><span>Sklepy internetowe</span></div>
              </div>

              <div class="contact">
                <!-- <div class="arrow">
                  <img src="/arrow.svg" alt="">
                </div>
                <div class="button_wrap">
                  <div class="button_inner">
                    <div class="button" @click="changeStatus(this.navItem)">Realizacje</div>
                  </div>
                </div> -->
                <div class="contact_left">
                  <p ><a href="https://www.linkedin.com/in/michal-ostrowicki-812b0157/" class="email footer_link">LinkedIn</a></p>
                  <p ><a href="mailto:kontakt@studiomos.pl" class="email footer_link">kontakt@studiomos.pl</a></p>
                  <!-- <p>Zapraszam do współpracy i kontaktu</p> -->
                  <p>{{translate('wspolpracaIKontakt', lang)}}</p>
                </div>
                <div class="contact_right">
                  <div class="button_wrap">
                    <div class="button_inner">
                      <div class="button footer_link" @click="changeStatus(this.navItem)">Realizacje</div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
      </div>

      
    </div>

  </section>
</template>

<script>
import translationsjson from '@/assets/locales/messages.json';

const translations = translationsjson;

export default {
  name: 'About',
  props: {
    lang: {
        type: String,
        default: "pl"
    }
  },

  data () {
    return {
      CurrentComponet:null,
      navItems: [
        {
          name: 'Home',
          url: '',
        },
        {
          name: 'About',
          url: '',
        },
        {
          name: 'Contact',
          url: '',
        }
      ],
      navItem: 'realizacje',
      language: true,
    }
  },
  // created() {
  //   console.log('created hook called', this.msg);
  //   console.log('created hook called', this.current);
  // },
  methods: {
    translate(str) {
      return translations[this.lang][str];
    },
     changeStatus(index) {

      // let thisElement = this.navItems[index];
      // console.log(index);
      // thisElement.status = true;

      this.$emit('clicked', index);
      // console.log(thisElement.name);
      // $('.navigation_item').each(function() {
      //   if ( $(this).attr('data-name') == index ) {
      //     $(this).addClass('active');
      //     console.log($(this).attr('data-name'));
      //   } else {
      //     $(this).removeClass('active');  
      //   }
      // });
      
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .fadeIn {
    animation: fadeIn 4s;

  }
  .type {
    color:#0000;
    background:
      linear-gradient(-90deg, #2c3e50 5px, rgba(0, 0, 0, 0) 0) 10px 0, linear-gradient(#2c3e50 0 0) 0 0;
    background-size:calc(var(--n)*1ch) 200%;
    -webkit-background-clip:padding-box,text;
    background-clip:padding-box,text;
    background-repeat:no-repeat;
    animation: 
     b .7s infinite steps(1),   
     t calc(var(--n)*.3s) steps(var(--n)) forwards;
  }

  @keyframes t{
    from {background-size:0 200%}
  }
  @keyframes b{
    50% {background-position:0 -100%,0 0}
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    66% { opacity:0; }
    100% { opacity: 1; }
  }
  .content {
    text-align: left;
    max-width: 100%;
    font-size: 16px;
    float:left;
    font-family: "Roboto Mono", monospace;
    .inner_content {
      width: 100%;
      margin-top: 15em;
      padding-top: 40px;
      float:left;
      /*width: calc(100% + 40px);
      margin-left: -20px;*/
      .services {
        margin-bottom: 60px;
        margin-top: 30px;
        float: left;
        gap:5px;
        display:flex;
        flex-direction: column;
        width: 100%;
        transition: box-shadow .3s;
        display: grid;
        grid-column-gap: 20px;
        grid-row-gap: 0;
        grid-template-rows: auto;
        grid-template-rows: 1fr 1fr 1fr;
        grid-auto-flow: column;
        grid-template-columns: 200px;
        div {
          width:100%;
          float:left;
          position: relative;
          span {
            font-size: 22px;
            font-family: "Roboto Mono", monospace;
            line-height: 32px;
            letter-spacing: 1px;
            text-transform: uppercase;
            float:left;
            width:auto;
            padding:0px;
            position: relative;
            cursor:default;
          }
        }
      }
      .content_wrap {
        float:left;
        width:100%;
        margin-top: 60px;
        .offer {
          display: flex;
          gap:40px;
          flex-wrap:wrap;
          margin-top: 60px;
          div {
            width:calc(50% - 20px);
            padding:20px;
            height: 300px;
            border:2px solid #2c3e50;
            display: flex;
            justify-content: center;
            align-items:center;
            span {
              float:left;
              line-height: 50px;
              text-align: center;
              position: relative;
              font-size: 22px;
              font-family: "Kanit";
              font-family: "Roboto Mono", monospace;
              letter-spacing: 1px;
              text-transform: uppercase;
              &:hover {
                cursor: default;
              }
              &:after {
                content: "";
                position: absolute;
                z-index: -1;
                right: 0;
                width: 0;
                bottom: 0px;
                background: #2c3e50;
                height: 2px;
                transition-property: width;
                transition-duration: 0.3s;
                transition-timing-function: ease-out;
              }
            }
          }
        }
      }
    }
    .intro_content {
      float:left;
      width:100%;
      max-width: 500px;
    }
  }
  .contact {
    margin-top: 80px;
    text-align: center;
    position: relative;
    padding-top: 100px;
    display: flex;
    width:100%;
    justify-content: space-between;
    align-items: flex-end;
    .contact_left {
      display: flex;
      flex-direction: column ;
      justify-content: flex-start;
      text-align: left;
      p {
        margin-bottom: 5px;
        font-size: 14px;
        letter-spacing: 1px;
      }
    }
    .contact_right {
      margin-bottom: 5px;
    }
    .email {
      text-decoration: underline;
      color: #2c3e50;
    }
    .arrow {
      width: 100%;
      max-width: 100px;
      margin:auto;
      height: 100px;
      position: absolute;
      top: 0;
      left: 50%;
      transform:translateX(-50%);
      animation: jumpInfinite 1.5s infinite;
      pointer-events: none;
      img {
        filter: invert(17%) sepia(4%) saturate(6381%) hue-rotate(170deg) brightness(91%) contrast(77%);
      }
    }
    .button_wrap {
      width:100%;
      margin:auto;
      display:flex;
      justify-content: center;

      display: flex;
      text-align: left;
      max-height: 80px;
      .button_inner {
        cursor: pointer;
        .button {
          margin: 0px 0px 10px 0px;
          font-family: "Montserrat";
          font-family: "Roboto Mono", monospace;
          font-weight: 400;
          text-transform: capitalize;
          color: #2c3e50;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 0px;
          opacity: 0.5;
          letter-spacing: 1px;
          transition: opacity 0.3s ease-in-out;
        }
        &:hover {
          .button {
            opacity:1;
          }
        }
      }
    }
  }
  /*@media screen (max-width: 767px) {
    .content {
      max-width: 100%;
    }
  } */
  @keyframes jumpInfinite {
    0% {
      margin-top: 0;
    }
    50% {
      margin-top: 20px;
    }
    100% {
      margin-top: 0;
    }
  }

@media screen and (max-width: 767px) {
  .content {
    .inner_content {
      .content_wrap {
        span {
          font-size: 14px;
        }
        .offer {
          margin-top: 40px;
          gap:5px;
          div {
            width: 100%;
            border: 0;
            height: auto;
            justify-content: flex-start;
            padding: 0;
            span {
              font-size: 24px;
              line-height: 34px;  
              text-align:left;
            }
          }
        }
      }
      .services {
        padding:20px 0;
        margin-bottom: 40px;
        grid-template-columns: auto;
        grid-column-gap: 10px;
        margin-top: 0;
        div {
          span {
            font-size: 24px;
          }
        }
      }
    }
  }
  .contact {
    flex-direction: column;
    justify-content: flex-start;
    .arrow {
      max-width: 60px;
    }
    .contact_left {
      width: 100%;
      order:2;
      gap:5px;
      p {
        font-size: 12px;
        line-height: 14px;
      }
    }
    .contact_right {
      order: 1;
      width: 100%;
    }
    .button_wrap {
      justify-content: flex-start;
      .button_inner {
        .button {
          font-size: 12px;
          opacity: 1;
        }
      }
    }
  }
}
</style>
