<template>
  <section class="main">
    <div class="navigation">
      <div class="navigation_item" :class="{ 'active': index === 0 }" :data-name="item.name" v-for="(item, index) in navItems">
        <div class="navigation_item_content" @click="changeStatus(index)"  >
          {{item.name}}
        </div>
      </div>
      <!-- <div class="navigation_item lang" >
        <div class="navigation_item_content" :data-lang="item.lang" @click="changeLang(index)" v-for="(item, index) in language" >
          {{item.name}}
        </div>
      </div> -->
    </div>
    <div class="logo">
      <!-- <img src="../assets/mos3.png" alt=""> -->
      <a href="/" class="custom"> &lt;/studio<span>mos</span>></a>
    </div>
  </section>
</template>

<script>

import $ from 'jquery'


export default {
  name: 'Main',
  // props: [
  //   'current'
  // ], 
  props: {
    msg: String,
    current: Boolean,
    lang: {
        type: String,
        default: "pl"
    }
  }, 
  watch: { // It listens to the change in prop name
    current: function () {
      // console.log(this.current); // print out when the name changes
      let currentEl = this.current;
      $('.navigation_item').each(function() {
        if ( $(this).attr('data-name') == currentEl ) {
          $(this).addClass('active');

        } else {
          $(this).removeClass('active');  
        }
      });
      setTimeout(() => {
        $("html, body").animate({ scrollTop: 0 }, 800);
      }, 400);
    },
  },
  data () {
    return {
      status: true,
      navItems: [
        {
          name: 'info',
        },
        {
          name: 'realizacje',
        }
      ],
      language: [
        {
          name: 'PL',
          lang: 'pl'
        },
        {
          name: 'ENG',
          lang: 'eng'
        }
      ]

    }
  },
  methods: {
    changeStatus(index) {

      let thisElement = this.navItems[index];
      // console.log(thisElement.name);
      // thisElement.status = true;

      this.$emit('clicked', thisElement.name);
      // console.log(thisElement.name);
      $('.navigation_item').each(function() {
        if ( $(this).attr('data-name') == thisElement.name ) {
          $(this).addClass('active');
        } else {
          $(this).removeClass('active');  
        }
      });
      
    },
    
  },
  mounted() {
       // this.getUnits();
    // console.log( window.location.href ) ;
    


  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .logo {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    text-align: left;
    order:1;
    img {
      position: relative;
      top: 0;
      left: 0;
      width: 150px;
      -o-object-fit: cover;
      height: auto;
    }
    .custom {
      font-weight: 400;
      font-size: 22px;
      float:left;
      cursor:pointer;
      text-decoration: none;
      color: #2c3e50;
      span {
        font-weight: 800;
      }
    }
  }
  .navigation{
    width: 100%;
    float: left;
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 40px;
    order:2;
    justify-content: flex-end;
    .navigation_item {
      display: flex;
      text-align: left;
      cursor: pointer;
      max-height: 80px;
      overflow: hidden;
      &:hover {
        .navigation_item_content {
          opacity:1;
        }
      }
      &.active {
        .navigation_item_content {
          opacity: 1;
        }
      }
      .navigation_item_content {
        margin: 0px 0px 10px 0px;
        font-family: 'Montserrat';
        font-family: "Roboto Mono", monospace;
        font-weight: 400;
        text-transform: capitalize;
        color: #2c3e50;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0px;
        opacity: .5;
        letter-spacing: 1px;
        transition:opacity .3s ease-in-out;
        padding: 10px;
        &:after { 
          content:'|';
          opacity:0;
        }
        &:hover {
          opacity:1;
        }
      }
      &.lang {
        margin-top: 40px;
        .navigation_item_content {
          &:first-child {
            &:after {
              content:'/';
              width:10px;
              height:10px;
              position: relative;
              margin:0 6px;
            }
          }
        }
      }
    }
  }
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

@media screen and (max-width: 767px) {

  section.main {
    gap: 20px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 5em;
  }
 
  .navigation {
    gap: 10px;
    .navigation_item {
       .navigation_item_content {
        font-size: 14px;
        padding: 0;
       }
    }
  }

  .logo {
    text-align: center;
    .custom {
      font-size: 16px;
    }
    img {
      width:100px;
    }
  }


}

@keyframes blink {
  0% { opacity: 1; }
   49% { opacity: 1; }
   50% { opacity: 0; }
  100% { opacity: 0; }
}


</style>
