<template>
  <div class="content">
    <div class="content_wrapper">
      <transition name="fade">
        <Main     @clicked="swapComponent" v-bind:current="currentComponent" lang="pl"/>
      </transition>
      <transition name="fade">      
        <About    @clicked="swapComponent" v-if="this.currentComponent == 'info'" lang="pl"/>
        <Projects v-else lang="pl"/>
      </transition>
    </div>
  </div>
</template>

<script>
import Main from '@/components/Main.vue'
import About from '@/components/About.vue'
import Projects from '@/components/Projects.vue'

export default {
  name: 'HomeView',
  props: {
    status: Boolean
  }, 
  components: {
    Main,
    About,
    Projects
  },
   data () {
    return {
      currentComponent: 'info'
    }
  },
  methods: {
    swapComponent(component) {
      this.currentComponent = component;
    }
  }
}
</script>

<style lang="scss">

  .content_wrapper {
    padding: 20px;
  }
  .fade-enter-active {
    transition: opacity 1.5s ease-in;
  }
  .fade-leave-active {
    transition: opacity .5s ease-out;
  }
  .fade-enter-from, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  @media screen and (max-width: 767px) {

    p {
      font-size: 14px;
    }
    .content_wrapper {
      padding: 0;
    }

  }

</style>