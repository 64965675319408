<template>
  <section class="projects">
    <!-- <h1>Projects</h1> -->
    
    <div class="inner_content_wrapper">
      <a :href="item.url" target="_blank" class="project" v-for="(item, index) in projects"  >
        <!-- <img :src="item.image" width="200px"> -->
        <div class="project_item_content" @click="changeStatus(index)" >
          {{item.title}}
        </div>
        <span class="project_url">{{item.url}}</span>
        <div class="project_item_tech" >
          <div class="project_tech" v-for="tek in item.tech">
            {{tek}}
          </div>
        </div>
      </a>  
    </div>

  </section>
</template>

<script>
export default {
  name: 'Projects',
  props: {
    msg: String,
    lang: {
        type: String,
        default: "pl"
    }
  }, 
  data () {
    return {
      language: true,
      projects: [
        {
          title: 'RockinIT.pl',
          tech: ['Wordpress', 'JavaScript', 'PHP', 'SCSS' ],
          image: '/mos_cover.jpg',
          url: 'https://www.rockinit.pl'
        },
        {
          title: 'Outworking.pl',
          tech: ['Wordpress', 'JavaScript', 'PHP', 'SCSS' ],
          image: '/mos_cover.jpg',
          url: 'https://www.outworking.pl'
        },
        {
          title: 'InsideYou.pl',
          tech: ['Wordpress', 'PHP', 'SCSS' ],
          image: '/mos_cover.jpg',
          url: 'https://www.insideyou.pl'
        },
        {
          title: 'Bemios.pl',
          tech: ['Wordpress', 'JavaScript', 'PHP', 'SCSS' ],
          image: '/mos_cover.jpg',
          url: 'https://www.bemios.pl'
        },
        {
          title: 'Inspirize.us',
          tech: ['Webflow', 'JavaScript', 'SCSS' ],
          image: '/mos_cover.jpg',
          url: 'https://www.inspirize.us'
        },
        {
          title: 'Greater Copenhagen Region',
          tech: ['HubSpot', 'JavaScript','CSS' ],
          image: '/mos_cover.jpg',
          url: 'https://www.greatercphregion.com/'
        },
        {
          title: 'Geoland',
          tech: ['Wordpress', 'JavaScript', 'CSS' ],
          image: '/mos_cover.jpg',
          url: 'https://geoland-kolobrzeg.pl/'
        },
        {
          title: 'Posadzkarskie.pl',
          tech: ['Wordpress', 'PHP', 'SCSS' ],
          image: '/mos_cover.jpg',
          url: 'https://www.posadzkarskie.pl'
        },
        {
          title: 'Hospicjum Gdynia',
          tech: ['Wordpress', 'JavaScript', 'CSS' ],
          image: '/mos_cover.jpg',
          url: 'http://hospicjum.gdynia.pl/'
        },
        {
          title: 'Orłowska',
          tech: ['Wordpress', 'JavaScript', 'CSS' ],
          image: '/mos_cover.jpg',
          url: 'https://orlowskaproperty.pl/'
        }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
/*    color: #42b983;*/
    color: #2c3e50;
  }
  .inner_content_wrapper {
    widht:100%;
    display: grid; 
    grid-template-columns: calc(50% - 20px) calc(50% - 20px);
    gap:40px 40px;
    .project {
      text-decoration: none;
      min-height:300px;
      position: relative;
      border:2px solid #2c3e50;
      display: flex;
      justify-content: center;
      align-items:center;
      transition: box-shadow .3s;
      box-shadow: 0px 0px #2c3e50;
      cursor: pointer;
      font-size: 24px;
      line-height: 50px;
      &:hover {
         .project_url {
          opacity:1;
        }
      }
      &:hover {
        box-shadow: 5px 5px #2c3e50;
        .navigation_item_content {
          opacity:1;
        }
        .project_item_tech {
          opacity:1;
        }
       
      }
      .project_item_content {
        position: relative;
        float:left;
        width: 100%;
        height: calc( 100% - 25px );
        text-align: center;
        text-transform: uppercase;
        font-size:16px;
        line-height: 22px;
        position: absolute;
        top:0;
        left:0;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity:1;
/*        background:rgba(0,0,0, .95);*/
        transition: opacity .3s;
/*        color:white;*/
        font-weight: 600;
        letter-spacing: 1px;
        display: flex;
        flex-direction: column;
      }
      .project_url {
          transition: opacity .3s;
          opacity: 0;
          color: #2c3e50;
          font-weight: 400;
          font-size: 16px;
          float: left;
          margin-top: 30px;
        }
      .project_item_tech {
        display: flex;
        gap:10px;
        float: left;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size:13px;
        position: absolute;
        bottom: 0;
        opacity: 0;
        transition: opacity .3s;
      }
      img {
        object-fit: cover;
        width:100%;
        position: relative;
        float:left;
        margin-bottom:10px;
      }
    }
  }
  /*@media screen ( max-width: 768px ) {
    .inner_content_wrapper {
      grid-template-columns: 100%;
    }
  }*/
  @media screen and (max-width: 767px) {

    .inner_content_wrapper {
      grid-template-columns: 100%;
      .project {
        min-height: 250px;
        .project_item_content {

        }
        .project_url {
          opacity: 1;
          font-size:14px;
          margin-top: 40px;
        }
        .project_item_tech {
          opacity: 1;
        }
      }
    }
      
  }
  
</style>
