<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view/>
</template>

<style lang="scss">
  * {
    box-sizing: border-box;
  }
  #app {
    /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
    font-family: 'Montserrat', sans-serif;
    font-family: "Roboto Mono", monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;

    max-width: 1000px;
    margin: auto;
    position: relative;
    width: 100%;
    padding-top: 40px;
  }
  p {
    margin-top: 0;
    font-size: 16px;
    line-height: 26px;
    font-family: "Roboto Mono", monospace;
  }

  .content_wrapper {
    position: relative;
    float: left;
/*    margin-bottom: 80px;*/
    width: 100%;
  }

  section {
    float: left;
    width: 100%;
    position: relative;
    &.main {
      padding-bottom: 10rem;
      width: 100%;
      display: flex;
    }

  }

  nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  /*@media screen (max-width:767px) {

    section {
      .main {
        flex-direction: column;
        .navigation {
          order:2;
          .navigation_item {
             .navigation_item_content {
              font-size:60px;
              line-height: 60px;
             }
          }
        }
        .logo {
          text-align: left;
          padding-top: 0px;
          padding-bottom: 20px;
          img {
            width: 100px;
          }
        }
      }
    }
    
  }*/
   @media screen and (max-width: 767px) {

    #app {
      float: left;
      padding: 20px;  
    }

  }
</style>
